'use client';

import LogoDark from '@job-ish/ui/assets/images/job-ish_logo_dark.webp';
import LogoLight from '@job-ish/ui/assets/images/job-ish_logo_light.webp';
import NextImage from 'next/image';
import {twMerge} from 'tailwind-merge';

export type LogoProps = {
	className?: string;
	height?: number;
	width?: number;
};

const Logo = ({className, height = 75, width = 125}: LogoProps) => (
	<>
		<NextImage
			alt="job-ish logo"
			className={twMerge('hidden dark:block', className)}
			height={height}
			priority
			src={LogoDark}
			width={width}
		/>
		<NextImage
			alt="job-ish logo"
			className={twMerge('block dark:hidden', className)}
			height={height}
			priority
			src={LogoLight}
			width={width}
		/>
	</>
);

export default Logo;
